import * as React from "react"
import Layout from "../components/layout"
import Fdfm from "../components/fdfm/Fdfm"
import Seo from "../components/seo"

const FdfmPage = () => (
  <Layout>
    <Seo title="14 Day Filmmaker | Learn Pro Content Creation In Just 14 Days" />
    <Fdfm />
  </Layout>
)

export default FdfmPage
