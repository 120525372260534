import React from "react"

export default function YouTubeIframe({ youtubeId, title }) {
  return (
    <div className="iframe-container">
      <iframe
        style={{ borderRadius: "5px", border: "0" }}
        width="560"
        height="315"
        src={"https://www.youtube.com/embed/" + youtubeId}
        title={title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )
}
