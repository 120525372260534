import React from "react"
import { Container } from "react-bootstrap"
import styled from "styled-components"

import FdfmWhiteLogo from "../../assets/images/fdfm/white-logo.jpeg"

export default function DisclaimerBar2() {
  return (
    <section className="themes__panel-light">
      <Container className="themes__panel-light themes__flex-center-all pt-4">
        <ContentContainer2>
          <div>
            <BasicImage1
              src={FdfmWhiteLogo}
              alt="14 Day Filmmaker Logo"
              style={{ height: "80px", margin: "30px 0 25px 0" }}
            />
            <BasicP2>
              © 2020 Paul Xavier Internattional, LLC MrPaulXavier.com
            </BasicP2>
            <BasicP2>
              By visiting this page, you agree to our&nbsp;
              <a
                href="/terms-and-conditions"
                title="Terms and Conditions"
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>
              ,&nbsp;
              <a
                href="/earnings-disclaimer"
                title="Earnings Disclaimer"
                target="_blank"
                rel="noreferrer"
              >
                Earnings Disclaimer
              </a>
              , &&nbsp;
              <a
                href="/privacy-policy"
                title="Privacy Policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </BasicP2>
            <BasicP2>
              This site is not a part of the Facebook website or Facebook Inc.
              Additionally, This site is NOT endorsed by Facebook in any way.
              FACEBOOK is a trademark of FACEBOOK, Inc.
            </BasicP2>
            <BasicP2>
              DISCLAIMER: The sales figures stated on this landing page and
              discussed in the 14 Day Filmmaker program are our personal sales
              figures and in some cases the sales figures of previous or
              existing clients. Please understand these results are not typical.
              We’re not implying you’ll duplicate them (or do anything for that
              matter). The average person who buys “how to” information gets
              little to no results. We’re using these references for example
              purposes only. Your results will vary and depend on many factors
              including but not limited to your background, experience, and work
              ethic. All business entails risk as well as massive and consistent
              effort and action. If you’re not willing to accept that, please DO
              NOT PURCHASE THE 14 DAY FILMMAKER COURSE.
            </BasicP2>
          </div>
        </ContentContainer2>
      </Container>
    </section>
  )
}

const ContentContainer2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  width: 80%;
  padding-bottom: 20px;
`

const BasicP2 = styled.p`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
`

const BasicImage1 = styled.img``
